/* styles */
.image--thanks {
  margin-bottom: 18px;
}
.image--thanks img {
  width: 100%;
  display: block;
  margin: 0 auto;
}
.image--media {
  text-align: center;
}
.image--media img {
  display: inline-block;
  width: 100%;
  max-width: 108px;
}
@media (min-width: 768px) {
  .image--media img {
    max-width: 143px;
  }
}
.paragraph {
  margin-bottom: 28px;
  font-size: 14px;
  line-height: 20px;
}
@media (min-width: 768px) {
  .form__image {
    margin-bottom: 48px;
  }
  .paragraph {
    margin-bottom: 48px;
    font-size: 16px;
    line-height: 30px;
  }
}
.paragraph strong {
  font-weight: 700;
}
.feed_header {
  font-size: 12px;
  line-height: 20px;
  text-align: left;
  background-color: #fff5e7;
  max-width: 900px;
  margin: 0 auto;
  border-width: 1px;
  border-style: solid;
  border-color: #e3e3e3;
  border-radius: 4px 4px 0 0;
  padding: 10px 16px;
}
@media (min-width: 768px) {
  .feed_header {
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    padding: 18px 16px;
  }
}
.step--thankyou {
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 10px;
}
@media (min-width: 768px) {
  .step--thankyou {
    font-size: 34px;
    line-height: 38px;
  }
}
