.autocomplete-input {
  text-align: left;
}
.autocomplete-input .select__menu {
  z-index: 3;
  text-align: left;
}
.autocomplete-input .select__value-container {
  padding: 0;
}
.autocomplete-input .select__single-value {
  padding: 30px 20px 18px;
}
.autocomplete-input .select__indicators {
  display: none;
}
@media screen and (prefers-reduced-motion: reduce) {
  .autocomplete-input .select__control .select__placeholder {
    transition: none;
  }
}
.autocomplete-input .select__control .select__placeholder {
  padding: 30px 18px 18px;
  opacity: 0;
  transition: opacity 0.2s;
}
.autocomplete-input .select__control--is-focused .select__placeholder {
  opacity: 1;
}
.shadow-input {
  position: relative;
  width: 100%;
}
.shadow-input__background {
  position: absolute;
  color: #808080;
  height: 60px;
  width: 100%;
  background-color: transparent;
  border: 0;
  padding: 30px 20px 18px;
  z-index: 2;
  text-align: left;
  font-size: 16px;
  line-height: 0.8;
}
@media (min-width: 1024px) {
  .shadow-input__background {
    line-height: 1.4;
    height: 70px;
  }
}
.shadow-input__hide-text {
  opacity: 0;
}
