.error {
  display: flex;
  max-height: calc(90vh - 142px);
  max-width: 75%;
  margin: 0 auto;
}
.error__header {
  text-align: left;
  font-weight: bold;
  font-size: 144px;
  line-height: 1.2;
  margin-bottom: 8px;
  letter-spacing: 1px;
}
.error__button {
  margin-top: 48px;
  text-align: left;
}
.error__description {
  text-align: left;
  font-size: 24px;
  line-height: 1.2;
}
.error__inner {
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: 50%;
  width: 100px;
  align-self: center;
}
.error__image_wrapper {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 50%;
}
.error__image {
  height: 100%;
  object-fit: contain;
}
