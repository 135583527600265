.price-input {
  position: relative;
}
.price-input__hint {
  position: absolute;
  color: #333;
  height: 60px;
  width: 100%;
  background-color: transparent;
  border: 0;
  padding: 30px 20px 18px;
  z-index: 2;
  text-align: left;
  font-size: 16px;
  line-height: 0.8;
}
@media (min-width: 1024px) {
  .price-input__hint {
    line-height: 1.4;
    height: 70px;
  }
}
.price-input__value {
  color: transparent;
}
.price-input__value--blured {
  color: inherit;
}
.price-input__currency--focused {
  color: #ccc;
}
