@media screen and (prefers-reduced-motion: reduce) {
  .btn {
    display: inline-block;
    background-color: var(--main-color);
    border: 0 none;
    color: #fff;
    padding: 16px 58px;
    border-radius: 4px;
    font-size: 16px;
    line-height: 20px;
    font-family: "Open Sans", sans-serif;
    cursor: pointer;
    font-weight: 400;
    width: 100%;
    text-decoration: none;
    transition: none;
  }
  .btn:hover,
  .btn:focus,
  .btn:active {
    text-decoration: none;
    background-color: var(--main-color--lighten);
  }
  .btn--success {
    background-color: var(--main-color);
  }
  .btn--innactive {
    background-color: #f7f7f7;
    color: #7c7c7c;
    order: 2;
  }
  .btn--innactive:hover,
  .btn--innactive:focus,
  .btn--innactive:active {
    background-color: #f1f1f1;
  }
  .btn--feed {
    padding: 10px 28px;
    font-size: 14px;
    max-width: 100%;
  }
}
@media screen and (prefers-reduced-motion: reduce) and (min-width: 1024px) {
  .btn {
    font-size: 14px;
  }
}
@media screen and (prefers-reduced-motion: reduce) and (min-width: 768px) {
  .btn {
    padding: 20px 58px;
    font-size: 16px;
  }
}
@media screen and (prefers-reduced-motion: reduce) and (min-width: 550px) {
  .btn {
    width: auto;
  }
}
@media screen and (prefers-reduced-motion: reduce) and (min-width: 550px) {
  .btn--feed {
    max-width: 120px;
  }
}
.btn {
  display: inline-block;
  background-color: var(--main-color);
  border: 0 none;
  color: #fff;
  padding: 16px 58px;
  border-radius: 4px;
  font-size: 16px;
  line-height: 20px;
  font-family: "Open Sans", sans-serif;
  cursor: pointer;
  font-weight: 400;
  width: 100%;
  text-decoration: none;
  transition: 0.2s;
}
@media (min-width: 1024px) {
  .btn {
    font-size: 14px;
  }
}
.btn:hover,
.btn:focus,
.btn:active {
  text-decoration: none;
  background-color: var(--main-color--lighten);
}
@media (min-width: 768px) {
  .btn {
    padding: 20px 58px;
    font-size: 16px;
  }
}
@media (min-width: 550px) {
  .btn {
    width: auto;
  }
}
.btn--success {
  background-color: var(--main-color);
}
.btn--success[disabled] {
  cursor: default;
  background-color: var(--main-color--disabled);
}
.btn--success[disabled]:hover,
.btn--success[disabled]:focus {
  background-color: var(--main-color--disabled);
}
.btn--innactive {
  background-color: #f7f7f7;
  color: #7c7c7c;
  order: 2;
}
.btn--innactive:hover,
.btn--innactive:focus,
.btn--innactive:active {
  background-color: #f1f1f1;
}
.btn--feed {
  padding: 10px 28px;
  font-size: 14px;
  max-width: 100%;
}
@media (min-width: 550px) {
  .btn--feed {
    max-width: 120px;
  }
}
