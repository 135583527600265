/* input component styles */
@media screen and (prefers-reduced-motion: reduce) {
  .input__group .input::placeholder {
    transition: none;
  }
}
.input__group .input::placeholder {
  opacity: 0;
  transition: 0.2s opacity ease-in-out;
}
.input__group.input--hasFocus .input::placeholder {
  opacity: 1;
  color: #808080;
}
@media (min-width: 768px) {
  .row {
    width: calc(100% + 16px);
    margin: 0 -8px 0;
    display: flex;
  }
  .row .input__group {
    width: calc(100% / 2);
    margin: 0 8px 20px;
  }
}
@media (min-width: 550px) {
  .row--nowrap {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}
.row--nowrap .input__group {
  width: auto;
}
@media (min-width: 768px) {
  .row--nowrap .input__group {
    width: auto;
  }
}
.input {
  font-family: inherit;
  width: 100%;
  background-color: transparent;
  border: 0;
  padding: 30px 20px 18px;
  z-index: 2;
  position: relative;
  font-size: 16px;
  height: 60px;
}
@media (min-width: 1024px) {
  .input {
    height: 70px;
  }
}
.input--hasFocus {
  background-color: #fff;
}
.input--valid {
  background-color: transparent;
  background-image: url("./img/check.png");
  background-repeat: no-repeat;
  background-position: calc(100% - 18px) 50%;
}
@media (min-width: 1024px) {
  .input--valid {
    background-position: calc(100% - 35px) 50%;
  }
}
@media screen and (prefers-reduced-motion: reduce) {
  .input--radio {
    background-color: initial;
    height: auto;
    width: auto;
    margin-right: 10px;
    padding: 0;
    transition: none;
  }
}
.input--radio {
  background-color: initial;
  height: auto;
  width: auto;
  margin-right: 10px;
  padding: 0;
  transition: none;
  margin-bottom: 0;
}
@media (min-width: 550px) {
  .input--radio {
    margin-bottom: 12px;
  }
}
@media (min-width: 768px) {
  .input--radio {
    margin-bottom: 0;
  }
}
.group {
  display: inline-block;
  margin-bottom: 18px;
  position: relative;
  background-color: #f7f7f7;
  border: 1px solid #f7f7f7;
  border-radius: 4px;
  width: 100%;
}
@media (min-width: 1024px) {
  .group {
    margin-bottom: 20px;
  }
}
.group--error {
  border-color: #f44336;
}
@media screen and (prefers-reduced-motion: reduce) {
  .group .group__label {
    transition: none;
  }
}
.group .group__label {
  position: absolute;
  line-height: 20px;
  color: #7c7c7c;
  left: 20px;
  top: calc(50% - 10px);
  transition: 0.2s;
  z-index: 1;
  width: 100%;
  margin-left: 0;
  max-width: calc(100% - 40px);
  text-overflow: ellipsis;
  overflow: hidden;
  display: inline-block;
  white-space: nowrap;
  text-align: left;
  font-size: 14px;
  cursor: pointer;
}
@media (min-width: 768px) {
  .group .group__label {
    max-width: 100%;
    font-size: 16px;
    position: absolute;
  }
}
.group .group__label--error {
  color: #f44336;
}
.group.input--hasFocus {
  background-color: #fff;
}
.group--button {
  background-color: #fff;
  border: 0 none;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  justify-content: flex-start;
}
@media (min-width: 768px) {
  .group--button {
    justify-content: flex-end;
  }
}
.group--checkbox {
  background-color: #fff;
  border: 0 none;
  margin: 0 0 30px;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -ms-align-items: center;
  align-items: center;
}
@media screen and (prefers-reduced-motion: reduce) {
  .group--checkbox .group__label {
    transition: none;
  }
}
@media screen and (prefers-reduced-motion: reduce) and (min-width: 768px) {
  .group--checkbox .group__label {
    position: absolute;
    left: 40px;
  }
}
.group--checkbox .group__label {
  position: relative;
  max-width: 100%;
  transition: none;
  font-size: 14px;
  white-space: normal;
  left: auto;
  color: #000;
}
.group--checkbox .group__label--error {
  color: #f44336;
}
.group--checkbox .group__label--error a {
  color: #f44336;
  background-image: linear-gradient(
    rgba(247, 0, 18, 0.2),
    rgba(247, 0, 18, 0.2)
  );
  background-size: 100% 1px;
  text-decoration: none;
  background-repeat: repeat-x;
  background-position: 0 100%;
}
@media (min-width: 768px) {
  .group--checkbox .group__label {
    position: absolute;
    left: 40px;
  }
}
.group--radio {
  background-color: #fff;
  border: 0 none;
  text-align: left;
}
@media screen and (prefers-reduced-motion: reduce) {
  .group__label {
    transition: none;
  }
}
.form__input:focus + .group__label,
.group.input--hasFocus .DayPickerInput + .group__label,
.group.input--hasFocus .price-input + .group__label,
.group.input--hasFocus .autocomplete-input + .group__label,
.input__group.input--hasValue .DayPickerInput + .group__label,
.input__group.input--hasValue .price-input + .group__label,
.input__group.input--hasValue .autocomplete-input + .group__label,
.input__group.input--hasValue .form__input + .group__label {
  top: 5px;
  font-size: 12px;
}
input::-webkit-datetime-edit {
  color: transparent;
}
input:focus::-webkit-datetime-edit {
  color: #000;
}
.input--hasValue input::-webkit-datetime-edit {
  color: #000;
}
