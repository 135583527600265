/* styles */
.form__image {
  margin-bottom: 26px;
  text-align: center;
  padding-top: 26px;
}
@media (min-width: 1024px) {
  .form__image {
    padding-bottom: 0;
    margin-bottom: 28px;
  }
}
.paragraph {
  margin-bottom: 12px;
  font-size: 14px;
  line-height: 20px;
}
@media (min-width: 1024px) {
  .paragraph {
    margin-bottom: 28px;
  }
}
@media (min-width: 768px) {
  .form__image {
    margin-bottom: 48px;
  }
  .paragraph {
    margin-bottom: 48px;
    font-size: 16px;
    line-height: 30px;
  }
}
.paragraph strong {
  font-weight: 700;
}
