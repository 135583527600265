.list {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 900px;
  margin: 0 auto;

  /* border: 1px solid #e3e3e3; */
  border-width: 0 1px 1px 1px;
  border-style: solid;
  border-color: #e3e3e3;
  border-radius: 0 0 4px 4px;
}
.list__item {
  display: flex;
  width: 100%;
  max-width: 100%;
  padding: 25px 18px;
  border-bottom: 1px solid #e3e3e3;
  position: relative;
  height: 200px;
}
.list__item:last-child {
  border: 0 none;
}
@media (min-width: 768px) {
  .list__item {
    height: 124px;
    padding-left: 160px;
    padding: 25px 34px 25px 160px;
  }
}
.list .item {
  position: relative;
}
.list .item__logo {
  height: 70%;
  max-width: 30px;
  position: absolute;
  width: 30px;
  right: 18px;
  left: auto;
  top: 8px;
  text-align: right;
  display: flex;
  justify-content: flex-end;
}
.list .item__logo img {
  max-width: 30px;
  max-height: 50px;
  display: inline-block;
  width: 100%;
  object-fit: contain;
}
@media (min-width: 768px) {
  .list .item__logo img {
    max-height: 100%;
    width: 100%;
    max-width: 60px;
    margin: 0 auto;
  }
}
@media (min-width: 768px) {
  .list .item__logo {
    width: 100%;
    left: 0;
    right: auto;
    text-align: center;
    top: 50%;
    transform: translateY(-50%);
    max-width: 160px;
  }
}
.list .item__data {
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: baseline;
  flex-wrap: wrap;
  padding-top: 50px;
}
@media (min-width: 768px) {
  .list .item__data {
    flex-wrap: nowrap;
    padding-top: 0;
    align-items: center;
  }
}
.list .col {
  display: flex;
  flex-direction: column;
  text-align: left;
  align-content: flex-end;
  justify-content: flex-end;
  height: auto;
  width: calc(100% / 3);
}
@media (min-width: 768px) {
  .list .col {
    width: calc(100% / 4);
    height: calc(100% - 10px);
  }
}
.list .col__header {
  margin-bottom: 20px;
  font-size: 14px;
  color: #222529;
  font-weight: 400;
  position: absolute;
  text-align: left;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: calc(100% - 80px);
}
@media (min-width: 550px) {
  .list .col__header {
    max-width: 280px;
  }
}
@media (min-width: 768px) {
  .list .col__header {
    max-width: 500px;
    font-size: 14px;
  }
}
.list .col__title {
  color: #a6a6a6;
  margin-bottom: 5px;
  font-weight: 500;
  text-transform: uppercase;
  font-size: 10px;
  line-height: 12px;
}
.list .col__value {
  font-size: 14px;
  line-height: 19px;
  font-weight: 500;
  white-space: nowrap;
}
@media (min-width: 768px) {
  .list .col__value {
    font-size: 16px;
  }
}
.list .col--left {
  text-align: left;
}
.list .col--center {
  text-align: center;
}
@media (min-width: 768px) {
  .list .col--center {
    text-align: left;
  }
}
.list .col--right {
  text-align: right;
}
@media (min-width: 768px) {
  .list .col--right {
    text-align: left;
  }
}
.list .col--btn {
  width: 100%;
  text-align: center;
  align-items: center;
  padding-top: 20px;
}
@media (min-width: 768px) {
  .list .col--btn {
    padding: 0;
    width: calc(100% / 4);
    align-items: flex-end;
  }
}
.list .col .value--bold {
  font-weight: bold;
}
