/* radio style */
.row--radio .group__label {
  font-size: 14px;
  display: inline-block;
  position: relative;
  left: auto;
  color: #000;
  white-space: normal;
}
.row--radio .group__label--error {
  color: #f44336;
}
.row--radio .group__label--error a {
  color: #f44336;
  background-image: linear-gradient(
    rgba(247, 0, 18, 0.2),
    rgba(247, 0, 18, 0.2)
  );
  background-size: 100% 1px;
  text-decoration: none;
  background-repeat: repeat-x;
  background-position: 0 100%;
}
.radioSet {
  display: inline-block;
  font-size: 14px;
  position: relative;
}
.radioSet label {
  cursor: pointer;
}
.radioSet .radioSet__mask {
  position: absolute;
  width: 16px;
  height: 16px;
  display: block;
  background-color: #f7f7f7;
  border-radius: 50%;
  left: 4px;
  top: 2px;
  z-index: 1;
}
.radioSet .radioSet__mask::after {
  content: "";
  display: none;
  width: 10px;
  height: 10px;
  position: absolute;
  background-color: #f7f7f7;
  left: 3px;
  top: 3px;
  border-radius: 50%;
}
.radioSet .input {
  opacity: 0;
  z-index: 2;
  cursor: pointer;
}
.radioSet .input:checked ~ .radioSet__mask {
  position: absolute;
  width: 16px;
  height: 16px;
  display: block;
  background-color: var(--main-color);
  border-radius: 50%;
  left: 4px;
  top: 2px;
  z-index: 1;
}
.radioSet .input:checked ~ .radioSet__mask::after {
  content: "";
  width: 10px;
  height: 10px;
  position: absolute;
  display: block;
  background-color: #fff;
  left: 3px;
  top: 3px;
  border-radius: 50%;
}
.radioSet:first-child {
  margin-right: 24px;
}
