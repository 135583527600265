.group--stepback {
  order: 2;
}
@media (min-width: 768px) {
  .group--stepback {
    order: 0;
  }
}
.group--checkbox a {
  text-decoration: none;
  color: var(--main-color);
}
.group--checkbox span {
  position: absolute;
  left: 0;
  top: -26px;
  font-size: 12px;
  color: #f44336;
  text-align: left;

  /* @media (min-width: 320px) {
   top: 0px;
   } */
}
@media (min-width: 550px) {
  .group--checkbox span {
    top: -26px;
  }
}
@media (min-width: 768px) {
  .group--checkbox span {
    top: -12px;
  }
}
@media (min-width: 768px) {
  .group--checkbox {
    margin: 0;
  }
}
.checkbox--customs {
  position: relative;
  margin-right: 11px;
}
.checkbox__icon {
  position: relative;
  visibility: visible;
  width: 20px;
  height: 20px;
  background-color: #fff;
  border: 1px solid #c5bbbb;
  border-radius: 4px;
  display: flex;
}
@media screen and (prefers-reduced-motion: reduce) {
  .checkbox__icon img {
    width: 10px;
    visibility: hidden;
    margin: 0 auto;
    transition: none;
  }
}
.checkbox__icon img {
  width: 10px;
  visibility: hidden;
  margin: 0 auto;
  transition: 0.2s ease-out;
}
.checkbox--error .checkbox__icon {
  border: 1px solid #f44336;
}
.checkbox .input--checkbox {
  position: absolute;
  opacity: 0;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  cursor: pointer;
  margin: 0;
}
.checkbox .input--checkbox:checked + .checkbox__icon {
  background-color: var(--main-color);
  border-color: var(--main-color);
}
@media screen and (prefers-reduced-motion: reduce) {
  .checkbox .input--checkbox:checked + .checkbox__icon img {
    visibility: visible;
    transition: none;
  }
}
.checkbox .input--checkbox:checked + .checkbox__icon img {
  visibility: visible;
  transition: 0.2s ease-in;
}
.checkbox--customs ~ span.notChecked {
  display: block;
}
.checkbox--customs ~ span {
  display: none;
}
.group--checkbox .form__input:focus + .group__label,
.group--checkbox.input--hasValue .form__input + .group__label {
  top: calc(50% - 10px);
  font-size: 14px;
}
