* {
  box-sizing: border-box;
}
html {
  overflow-y: hidden;
  overflow-x: inherit;
}

html,
body {
  font-family: "Open Sans", "Helvetica Neue", Helvetica, sans-serif;
}

.App {
  max-width: 1060px;
  margin: 0 auto;
  border-radius: 4px;
  overflow: hidden;
  font-family: "Open Sans", "Helvetica Neue", Helvetica, sans-serif;
}

.fonts-loaded .App {
  font-family: "Open Sans", "Helvetica Neue", Helvetica, sans-serif;
}

.lcform__header {
  background-color: var(--main-color);
  padding: 18px 16px;
}
@media (min-width: 1024px) {
  .lcform__header {
    padding: 27px 16px;
  }
}
.header__title,
.header__subtitle {
  color: #fff;
  font-weight: 300;
}
.header__title {
  font-size: 24px;
  line-height: 30px;
  font-weight: 300;
  margin-bottom: 10px;
}
.header__subtitle {
  font-size: 14px;
  line-height: 18px;
}
@media (min-width: 768px) {
  .header__title {
    font-size: 40px;
    line-height: 44px;
    font-weight: 300;
    margin-bottom: 10px;
  }
  .header__subtitle {
    font-size: 16px;
    line-height: 34px;
  }
}
.lcform__progressbar {
  border-width: 0 1px 1px 1px;
  border-color: #e2e2e2;
  border-style: solid;
  padding: 18px;
}
@media (min-width: 1024px) {
  .lcform__progressbar {
    padding: 28px;
  }
}
.progressbar__prediction {
  font-size: 14px;
  line-height: 20px;
}
.progressbar__percentage {
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
}
.lcform__form {
  border-width: 1px;
  border-style: solid;
  border-color: #e2e2e2;
  border-radius: 4px;
}
.lcform__form--with-header {
  border-width: 0 1px 1px 1px;
  border-radius: 0 0 4px 4px;
}
.lcform__form.form--container {
  padding: 18px 16px;
}
@media (min-width: 1024px) {
  .lcform__form.form--container {
    padding: 58px 16px;
  }
}
.row--footer {
  padding-top: 18px;
  display: flex;
  flex-direction: column;
}
@media (min-width: 768px) {
  .row--footer {
    flex-direction: row;
    padding-top: 12px;
  }
}
.row--thxpage {
  padding-top: 0;
}
@media (min-width: 768px) {
  .row--thxpage {
    padding-top: 18px;
  }
}
.step {
  max-width: 794px;
  width: 100%;
  margin: 0 auto;
  padding: 0;
}
@media (min-width: 550px) {
  .step {
    padding: 0 16px;
  }
}
.form__subtitle {
  font-size: 20px;
  line-height: 24px;
  margin: 20px 0 16px;
  text-align: left;
  font-weight: 300;
}
@media (min-width: 768px) {
  .form__subtitle {
    font-size: 22px;
    line-height: 30px;
    margin: 44px 0 22px;
  }
}
